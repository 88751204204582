import {Box} from "@mui/material";

//this is the widget for the user pic everywhere

const UserImage = ({ image, size ="60px"}) => {


    return (
        <Box width={size} height={size}>
            <img

                style={{objectFit: "cover", borderRadius: "50%"}}
                width={size}
                height={size}
                alt="user"
                src={`${process.env.REACT_APP_BASE_URL}/assets/${image}`}
                
            /> 

        </Box>
    )

}

export default UserImage;